<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-add-wrapper">
          <b-alert variant="danger" :show="order === undefined">
            <h4 class="alert-heading">Ошибка получения данных</h4>
            <div class="alert-body">
              Не найдено заказа с таким ID. Проверьте
              <b-link class="alert-link" :to="{ name: 'order-list'}">Список заказов</b-link>
              чтобы увидеть другие заказы.
            </div>
          </b-alert>
          <div v-if="order" class="row invoice-add">
            <div class="col-12 col-xl-9 col-md-8">
              <b-form @submit.prevent>
                <b-card no-body class="invoice-preview-card">
                  <b-card-body class="invoice-padding pb-0">
                    <h4 class="invoice-title mb-1">Заказ #{{ order.id }}</h4>
                    <div class="row">
                      <div class="form-group col-6">
                        <label class="title">Клиент:</label>
                        <v-select
                            v-if="users.length > 0"
                            v-model="order.user"
                            :options="users"
                            label="surname"
                            :disabled="order.user && order.items.length > 0"
                            :get-option-label="(option) => option.name + (option.surname ? ' ' + option.surname : '' )
                            + (option.company_name ? ' (' + option.company_name + ')' : '')"
                        >
                          <template #option="{ name, surname, company_name }">
                            <span>
                              {{ name }} {{ surname }} {{ company_name ? `(${company_name})` : '' }}</span>
                          </template>
                        </v-select>
                      </div>
                      <div class="form-group col-6">
                        <label class="title">Статус:</label>
                        <v-select
                            v-if="statuses.length > 0"
                            v-model="order.status"
                            :options="statuses"
                            label="name"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-4">
                        <label class="title">ТТН</label>
                        <input type="text" class="form-control" v-model="order.ttn">
                      </div>
                      <div class="col-12 col-lg-4">
                        <label class="title">Номер заказа клиента</label>
                        <input type="text" class="form-control" v-model="order.client_order">
                      </div>
                      <div class="col-12 col-lg-4">
                        <label class="title">Стоимость наложки</label>
                        <input type="number" class="form-control" v-model="order.client_price">
                      </div>
                    </div>
                    <hr class="invoice-spacing">
                    <h6 class="mb-1">Выбор товара</h6>
                    <div class="row">
                      <div class="col-6">
                        <label class="d-inline d-lg-none">Товар</label>
                        <v-select
                            v-model="selectedProductSpecification"
                            :options="products"
                            :filterable="false"
                            label="product_id"
                            :disabled="!order.user"
                            class="item-selector-title"
                            placeholder="Выберите товар"
                            @search="searchProduct"
                        >
                          <template #selected-option="{ size_name, product }">
                            {{ product.sku }} - Размер {{ size_name }}
                          </template>
                          <template #option="{ size_name, qty, condition_name, product }">
                            {{ product.sku }} - Размер {{ size_name }}<br>
                            <small>{{ product.name }} {{ product.brand_name }}</small><br>
                            <small>{{ condition_name }}, В наличии: {{ qty }} шт</small>
                          </template>
                        </v-select>
                      </div>
                      <div class="col-6">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            :disabled="!order.user"
                            @click="addProduct"
                        >
                          Добавить товар
                        </b-button>
                      </div>
                    </div>
                  </b-card-body>
                  <hr class="invoice-spacing">
                  <div class="table-responsive">
                    <table class="table b-table">
                      <thead>
                      <tr>
                        <th>Товар</th>
                        <th>Фото</th>
                        <th>Размер</th>
                        <th>Состояние</th>
                        <th>К-во</th>
                        <th>Вес (кг)</th>
                        <th v-if="$can('read', 'payments')">Цена (грн)</th>
                        <th v-if="$can('read', 'payments')">Сумма (грн)</th>
                        <th>Действия</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in order.items" :key="item.id" class="grouped-row">
                          <td>
                            <router-link
                                class="text-body"
                                :to="{name: 'product-details', params: { id: item.product_specification.product.id}}"
                            >
                              {{ item.product_specification.product.name }}
                              {{ item.product_specification.product.brand_name }}
                            </router-link>
                            <br/>
                            <small>Артикул: {{ item.product_specification.product.sku }}</small> <br/>
                          </td>
                          <td class="p-0">
                            <img
                                v-if="item.product_specification.product.small_main_image"
                                :src="`/img/product/${item.product_specification.product.small_main_image}`"
                                class="product-img" alt=""
                            >
                          </td>
                          <td class="text-uppercase">{{ item.product_specification.size_name }}</td>
                          <td>{{ item.product_specification.condition_name }}</td>
                          <td>
                            <input
                                type="number"
                                v-model="item.qty"
                                :max="item.product_specification.max_qty"
                                class="form-control qty-input"
                            >
                          </td>
                          <td>{{ item.product_specification.weight / 1000 }}</td>
                          <td v-if="$can('read', 'payments')">
                            {{ item.cost }} <span class="currency">грн</span>
                          </td>
                          <td v-if="$can('read', 'payments')">
                            {{ item.cost*item.qty }} <span class="currency">грн</span>
                          </td>
                          <td>
                            <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-body invoice-padding pb-0">
                    <div class="row">
                      <div class="col-12 col-md-6 order-2 order-md-1 mt-md-0 mt-3 d-flex align-items-center"></div>
                      <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end">
                        <div class="invoice-total-wrapper">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">Количество:</p>
                            <p class="invoice-total-amount">{{ summary.qty }}</p>
                          </div>
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">Вес (кг):</p>
                            <p class="invoice-total-amount">{{ summary.weight/1000 }}</p>
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">Сумма:</p>
                            <p class="invoice-total-amount">{{ summary.cost }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="invoice-spacing">
                  <div class="card-body invoice-padding pt-0">
                    <span class="font-weight-bold">Комментарии: </span>
                    <b-form-textarea v-model="order.comments"/>
                  </div>
                </b-card>
              </b-form>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <button class="btn btn-primary btn-block" @click="updateOrder">
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormTextarea,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'OrderEdit',
  components: {
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormTextarea,
    BAlert,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      users: [],
      statuses: [],
      selectedProductSpecification: null,
      products: [],
      order: {
        user: null,
        status: null,
        items: [],
        comments: null,
        ttn: null,
        client_order: null,
        client_price: null,
      },
    };
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'order.items': function () {
      this.initTrHeight();
    },
  },
  async mounted() {
    await this.getUsers();
    await this.getOrderStatuses();
    await this.getOrderInfo();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    async getUsers() {
      this.users = (await this.$api.users.list()).data;
    },
    async getOrderInfo() {
      this.order = (await this.$api.orders.get(this.$route.params.id)).data;
    },
    async updateOrder() {
      try {
        this.$api.orders.update(this.order.id, { ...this.order });
        this.$toast.success('Заказ успешно обновлен');
        await this.$router.push({ name: 'order-details', params: { id: this.order.id } });
      } catch (e) {
       // console.log(e);
      }
    },
    async searchProduct(query, loading) {
      if (query.length && this.order.user) {
        loading(true);
        this.$api.products.search({
          search: query,
          user_id: this.order.user.id,
        }).then((response) => {
          this.products = response.data;
          loading(false);
        });
      }
    },
    async getOrderStatuses() {
      this.statuses = (await this.$api.orders.statuses()).data;
      this.order.status = this.statuses.find((status) => status.id === 2);
    },
    addProduct() {
      this.order.items.push({
        product_specification: this.selectedProductSpecification,
        cost: this.selectedProductSpecification.user_price,
        qty: 1,
        max_qty: this.selectedProductSpecification.qty,
      });
    },
    removeItem(index) {
      this.order.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },
  },
  computed: {
    summary() {
      return {
        qty: this.order.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.qty, 10), 0),
        cost: this.order.items.reduce((acc, item) => acc + item.cost * item.qty, 0),
        weight: this.order.items.reduce((acc, item) => acc + item.product_specification.weight * item.qty, 0),
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
.invoice-edit-input {
  max-width: 100% !important;
}
.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.product-img {
  max-height: 80px;
}
.qty-input {
  width: 100px;
}
</style>
